.list {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (--desktop) {
    display: grid;
    grid-gap: var(--gap-large);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.item {
  margin: 0;
  padding: 0;

  @media (--phone) {
    &:not(:last-child) {
      margin-bottom: var(--gap-huge);
    }
  }
}
