.section {
  display: grid;
  gap: var(--gap-vertical-section);
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  padding-top: 0;

  @media (--tablet-) {
    grid-template-columns: 0.6fr 1fr;
  }

  @media (--phone) {
    grid-template-columns: 1fr;
  }

  &.divider {
    margin-top: 0;
    padding-top: var(--gap-vertical-section);
    position: relative;
    &::after {
      background-color: var(--color-neutral-20);
      content: '';
      height: 10px;
      left: calc(var(--width-container-offcet) * -1);
      position: absolute;
      right: var(--width-container-offcet);
      width: 100vw;
    }
  }
}

.content {
  margin-top: var(--gap-huge);

  @media (--tablet-) {
    margin-top: var(--gap-small);
  }

  @media (--phone) {
    margin-top: 0;
  }
}

.image {
  display: block;
  height: 420px;
  margin-right: auto;
  object-fit: contain;
  object-position: center;
  position: relative;

  width: 411px;

  &::before {
    background-image: url('./bg.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -10%;
    content: '';
    height: 229px;
    position: absolute;
    right: -20%;
    width: 310px;
  }

  @media (--tablet-) {
    height: 282px;
    margin: auto;
    width: 282px;

    &::before {
      bottom: -10%;
      right: -30%;
    }
  }
}

.footer {
  display: flex;
  flex-wrap: nowrap;
  margin-top: var(--gap-title);

  @media (--phone) {
    display: grid;
    gap: var(--gap-button);
    margin: 0 auto;
    width: 90%;
  }
}

@media (--tablet) {
  .button {
    margin-bottom: var(--gap-button);
  }

  .primaryButton {
    margin-right: var(--gap-button);
  }
}

@media (--phone) {
  .primaryButton {
    margin-top: var(--gap-large);
  }
}
