.skeleton {
  --base-color: var(--color-neutral-30);
  cursor: wait;
  display: inline-block;
  height: rem(16px);
}

.accentTheme {
  --base-color: var(--color-neutral-50);
}
