.markdown {
  --md-text-color: inherit;
  --md-text-size: inherit;
  color: var(--md-text-color);

  display: block;
  position: relative;

  img {
    max-width: 100%;
  }

  p,
  li {
    font-size: var(--md-text-size);
  }

  h1 {
    color: var(--md-h1-color, var(--md-text-color));
    margin-bottom: var(--gap-title);
  }

  & *:last-child {
    margin-bottom: 0;
  }
  p a:hover {
    color: var(--color-accent-primary);
  }
}
