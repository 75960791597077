.article {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  text-align: left;

  @media (--tablet-only) {
    display: grid;
    grid-gap: var(--gap);
    grid-template-columns: 0.7fr 1fr;
    margin-bottom: var(--gap-large);
  }
}

.imageContainer {
  aspect-ratio: 16 / 14;
  background-color: var(--main-article-color);
  border: 1px solid var(--main-article-color);
  border-radius: var(--container-border-radius);
  display: block;
  margin-bottom: var(--gap);
  overflow: hidden;
  position: relative;
  transition: var(--transition-duration) ease-in-out;
  width: 100%;

  img {
    transition: 0.5s ease-in-out;
  }

  &:hover {
    opacity: 0.8;
    transition: var(--transition-duration) ease-in-out;

    img {
      transform: scale(1.1);
      transition: 2s ease-in-out;
    }
  }

  @media (--tablet-only) {
    align-self: flex-start;
    aspect-ratio: 16/9;
  }

  @media (--phone) {
    aspect-ratio: 16/7;
  }
}

.image {
  object-fit: cover;
}

.title {
  -webkit-box-orient: vertical;
  color: var(--color-primary-400);
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 1.1em;
  margin: 0 0 var(--gap-small) 0;
  min-height: 3em;
  outline: none;
  overflow: hidden;
  text-decoration: none;

  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }

  @media (--tablet-) {
    margin-bottom: var(--gap-tiny);
    margin-top: rem(4px);
    min-height: unset;
    h4 {
      margin-bottom: 0;
    }
  }
  @media (--phone) {
    margin-top: 0;
  }
}

.tags {
  grid-area: tags;
  list-style: none;
  margin: rem(-6px) 0 0 0;
  padding: 0;
}

.tag {
  display: inline-block;
  margin-right: var(--gap-tiny);

  &::after {
    content: ', ';
  }

  &:last-child:after {
    display: none;
  }
}

.description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  grid-area: description;
  -webkit-line-clamp: 3;
  margin: var(--gap-small) 0 0 0;
  overflow: hidden;
  @media (--phone) {
    margin-top: calc(var(--gap-large) / 2);
  }
}
