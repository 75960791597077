.section {
  position: relative;
  z-index: inherit;
}

.content {
  --md-h1-color: var(--color-neutral-600);
  --md-text-size: var(--text-m);
  margin-right: var(--gap-large);
  max-width: 469px;
  padding: var(--gap-large) 0 0 0;

  position: relative;
  z-index: 1;

  @media (--tablet-only) {
    max-width: 45vw;
  }

  @media (--tablet-) {
    margin: 0 auto;
    padding: 0;
  }
}

.imageContainer {
  --welcome-image-bottom-offcet: calc(var(--gap-vertical-section) * -1 - 50px);

  margin-bottom: var(--welcome-image-bottom-offcet);
  margin-top: auto;
  position: relative;

  @media (--tablet-) {
    margin-bottom: var(--gap);

    &::after {
      background-color: var(--sun-color);
      border-radius: 50%;
      bottom: 10%;
      content: '';
      height: 50vw;
      min-height: 250px;
      min-width: 250px;
      opacity: 50%;
      position: absolute;
      right: 0;
      width: 50vw;
      z-index: -1;
    }
  }
}

.image {
  height: auto;
  object-fit: contain;
  object-position: right;
  width: 100%;

  @media (--tablet-) {
    object-position: center;
  }
}

.video {
  max-width: 500px;

  @media (--tablet-) {
    margin: 0 auto var(--gap);
    max-width: 100%;
  }
}
