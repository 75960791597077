.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.item {
  margin: 0;
  margin-right: var(--gap-tiny);
  padding: 0;

  &:not(:last-child)::after {
    content: ',';
  }
}
