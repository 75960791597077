.markdown_markdown__IIWWl {
  --md-text-color: inherit;
  --md-text-size: inherit;
  color: var(--md-text-color);

  display: block;
  position: relative;
}

  .markdown_markdown__IIWWl img {
    max-width: 100%;
  }

  .markdown_markdown__IIWWl p,
  .markdown_markdown__IIWWl li {
    font-size: var(--md-text-size);
  }

  .markdown_markdown__IIWWl h1 {
    color: var(--md-text-color);
    color: var(--md-h1-color, var(--md-text-color));
    margin-bottom: 29px;
    margin-bottom: var(--gap-title);
  }

.markdown_markdown__IIWWl *:last-child {
    margin-bottom: 0;
  }

.markdown_markdown__IIWWl p a:hover {
    color: #ff3368;
    color: var(--color-accent-primary);
  }

.tag_tag__yvVQA {
  color: #86664d;
  color: var(--color-tertiary-400);
  display: inline-block;
  font-size: 0.75rem;
  outline: none;
}
.tag_tag__yvVQA.tag_focus-visible__LoT8_,
  .tag_tag__yvVQA:hover {
    color: rgb(54, 41, 31);
  }
.tag_tag__yvVQA:focus-visible,
  .tag_tag__yvVQA:hover {
    color: rgb(54, 41, 31);
  }
.tag_tag__yvVQA > :first-letter {
    text-transform: uppercase;
  }

.welcome-section-container_mainContainer__e_Z4F {
  --bottom-decor-height: 10vw;
  background-color: #f3f8f9;
  background-color: var(--color-primary-50);
  overflow: hidden;
  padding-bottom: calc((var(--bottom-decor-height) / 2));
  position: relative;
  width: 100%;
}

.welcome-section-container_mainContainer__e_Z4F:after {
    background-color: rgba(255,255,255,1);
    background-color: var(--background-color-primary);
    border-radius: 50%;
    bottom: 0;
    box-shadow: inset 0 9px 8px rgba(0, 0, 0, 0.06);
    content: '';
    height: var(--bottom-decor-height);
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);
    width: 110vw;
    z-index: 1;
  }

@media (min-width: 63rem) {

.welcome-section-container_mainContainer__e_Z4F {
    --bottom-decor-height: 200px;
}
  }

.welcome-section-container_section__63Tdo {
  --sun-color: var(--color-secondary-200);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 62.999rem) {

.welcome-section-container_section__63Tdo {
    flex-direction: column-reverse;
    text-align: center;
}
  }

.welcome-section-container_bgSun__ho4fo:before {
    background-color: var(--sun-color);
    border-radius: 50%;
    bottom: 0;
    content: '';
    height: 30vw;
    max-height: 664px;
    max-width: 664px;
    opacity: 0.5;
    position: absolute;
    right: calc(1.56 * 1.5rem);
    right: var(--gap-large);
    transform: translate(15%, 45%);
    width: 30vw;
    z-index: 0;
  }

@media (max-width: 62.999rem) {

.welcome-section-container_bgSun__ho4fo:before {
      display: none;
  }
    }

.welcome-section-container_bgImage__Mt4eZ {
  object-fit: cover;
  object-position: center;
}

.tags-list_list__2gc1A {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.tags-list_item__qLhMC {
  margin: 0;
  margin-right: 0.375rem;
  margin-right: var(--gap-tiny);
  padding: 0;
}
.tags-list_item__qLhMC:not(:last-child)::after {
    content: ',';
  }

.skeleton_skeleton__Ufe1I {
  --base-color: var(--color-neutral-30);
  cursor: wait;
  display: inline-block;
  height: 1rem;
}

.skeleton_accentTheme__MKg4_ {
  --base-color: var(--color-neutral-50);
}

.welcome-section_section__kx_SR {
  position: relative;
  z-index: inherit;
}

.welcome-section_content__W_YTd {
  --md-h1-color: var(--color-neutral-600);
  --md-text-size: var(--text-m);
  margin-right: calc(1.56 * 1.5rem);
  margin-right: var(--gap-large);
  max-width: 469px;
  padding: calc(1.56 * 1.5rem) 0 0 0;
  padding: var(--gap-large) 0 0 0;

  position: relative;
  z-index: 1;
}

@media (min-width: 48rem) and (max-width: 62.999rem) {

.welcome-section_content__W_YTd {
    max-width: 45vw;
}
  }

@media (max-width: 62.999rem) {

.welcome-section_content__W_YTd {
    margin: 0 auto;
    padding: 0;
}
  }

.welcome-section_imageContainer__htLAM {
  --welcome-image-bottom-offcet: calc(var(--gap-vertical-section) * -1 - 50px);

  margin-bottom: var(--welcome-image-bottom-offcet);
  margin-top: auto;
  position: relative;
}

@media (max-width: 62.999rem) {

.welcome-section_imageContainer__htLAM {
    margin-bottom: 1.5rem;
    margin-bottom: var(--gap);
}

    .welcome-section_imageContainer__htLAM::after {
      background-color: var(--sun-color);
      border-radius: 50%;
      bottom: 10%;
      content: '';
      height: 50vw;
      min-height: 250px;
      min-width: 250px;
      opacity: 0.5;
      position: absolute;
      right: 0;
      width: 50vw;
      z-index: -1;
    }
  }

.welcome-section_image__RXfFa {
  height: auto;
  object-fit: contain;
  object-position: right;
  width: 100%;
}

@media (max-width: 62.999rem) {

.welcome-section_image__RXfFa {
    object-position: center;
}
  }

.welcome-section_video__I8AqS {
  max-width: 500px;
}

@media (max-width: 62.999rem) {

.welcome-section_video__I8AqS {
    margin: 0 auto 1.5rem;
    margin: 0 auto var(--gap);
    max-width: 100%;
}
  }

.cta-section_section__Dnjh_ {
  display: grid;
  grid-gap: 100px;
  grid-gap: var(--gap-vertical-section);
  gap: 100px;
  gap: var(--gap-vertical-section);
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  padding-top: 0;
}

@media (max-width: 62.999rem) {

.cta-section_section__Dnjh_ {
    grid-template-columns: 0.6fr 1fr;
}
  }

@media (max-width: 47.999rem) {

.cta-section_section__Dnjh_ {
    grid-template-columns: 1fr;
}
  }

.cta-section_section__Dnjh_.cta-section_divider__h_ytc {
    margin-top: 0;
    padding-top: 100px;
    padding-top: var(--gap-vertical-section);
    position: relative;
  }

.cta-section_section__Dnjh_.cta-section_divider__h_ytc::after {
      background-color: #f7f7f6;
      background-color: var(--color-neutral-20);
      content: '';
      height: 10px;
      left: calc(calc((100vw - var(--width-container)) / 2) * -1);
      left: calc(var(--width-container-offcet) * -1);
      position: absolute;
      right: calc((100vw - var(--width-container)) / 2);
      right: var(--width-container-offcet);
      width: 100vw;
    }

.cta-section_content__XMQmh {
  margin-top: calc(1.56 * calc(1.56 * 1.5rem));
  margin-top: var(--gap-huge);
}

@media (max-width: 62.999rem) {

.cta-section_content__XMQmh {
    margin-top: 0.75rem;
    margin-top: var(--gap-small);
}
  }

@media (max-width: 47.999rem) {

.cta-section_content__XMQmh {
    margin-top: 0;
}
  }

.cta-section_image___U0lk {
  display: block;
  height: 420px;
  margin-right: auto;
  object-fit: contain;
  object-position: center;
  position: relative;

  width: 411px;
}

.cta-section_image___U0lk::before {
    background-image: url(/_next/static/media/bg.adab14d6.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -10%;
    content: '';
    height: 229px;
    position: absolute;
    right: -20%;
    width: 310px;
  }

@media (max-width: 62.999rem) {

.cta-section_image___U0lk {
    height: 282px;
    margin: auto;
    width: 282px;
}

    .cta-section_image___U0lk::before {
      bottom: -10%;
      right: -30%;
    }
  }

.cta-section_footer__qUqYL {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 29px;
  margin-top: var(--gap-title);
}

@media (max-width: 47.999rem) {

.cta-section_footer__qUqYL {
    display: grid;
    grid-gap: 20px;
    grid-gap: var(--gap-button);
    gap: 20px;
    gap: var(--gap-button);
    margin: 0 auto;
    width: 90%;
}
  }

@media (min-width: 48rem) {
  .cta-section_button__WtX7O {
    margin-bottom: 20px;
    margin-bottom: var(--gap-button);
  }

  .cta-section_primaryButton__HnwET {
    margin-right: 20px;
    margin-right: var(--gap-button);
  }
}

@media (max-width: 47.999rem) {
  .cta-section_primaryButton__HnwET {
    margin-top: calc(1.56 * 1.5rem);
    margin-top: var(--gap-large);
  }
}

.article-tile_article__XO7JQ {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  text-align: left;
}

@media (min-width: 48rem) and (max-width: 62.999rem) {

.article-tile_article__XO7JQ {
    display: grid;
    grid-gap: 1.5rem;
    grid-gap: var(--gap);
    grid-template-columns: 0.7fr 1fr;
    margin-bottom: calc(1.56 * 1.5rem);
    margin-bottom: var(--gap-large);
}
  }

.article-tile_imageContainer__QGVRz {
  background-color: #bf916e;
  background-color: var(--main-article-color);
  border: 1px solid #bf916e;
  border: 1px solid var(--main-article-color);
  border-radius: 4px;
  border-radius: var(--container-border-radius);
  display: block;
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
  transition: var(--transition-duration) ease-in-out;
  width: 100%;
}

.article-tile_imageContainer__QGVRz::before {
  float: left;
  padding-top: 87.5%;
  content: '';
}

.article-tile_imageContainer__QGVRz::after {
  display: block;
  content: '';
  clear: both;
}

.article-tile_imageContainer__QGVRz img {
    transition: 0.5s ease-in-out;
  }

.article-tile_imageContainer__QGVRz:hover {
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    transition: var(--transition-duration) ease-in-out;
  }

.article-tile_imageContainer__QGVRz:hover img {
      transform: scale(1.1);
      transition: 2s ease-in-out;
    }

@media (min-width: 48rem) and (max-width: 62.999rem) {

.article-tile_imageContainer__QGVRz {
    align-self: flex-start;
}

.article-tile_imageContainer__QGVRz::before {
    float: left;
    padding-top: 56.25%;
    content: '';
}
.article-tile_imageContainer__QGVRz::after {
    display: block;
    content: '';
    clear: both;
}
  }

@media (max-width: 47.999rem) {

.article-tile_imageContainer__QGVRz::before {
    float: left;
    padding-top: 43.75%;
    content: '';
}
.article-tile_imageContainer__QGVRz::after {
    display: block;
    content: '';
    clear: both;
}
  }

.article-tile_image__ZUV2Z {
  object-fit: cover;
}

.article-tile_title___Sdkc {
  -webkit-box-orient: vertical;
  color: #5f808b;
  color: var(--color-primary-400);
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 1.1em;
  margin: 0 0 0.75rem 0;
  margin: 0 0 var(--gap-small) 0;
  min-height: 3em;
  outline: none;
  overflow: hidden;
  text-decoration: none;
}

.article-tile_title___Sdkc.article-tile_focus-visible__Ksehn,
  .article-tile_title___Sdkc:hover {
    text-decoration: underline;
  }

.article-tile_title___Sdkc:focus-visible,
  .article-tile_title___Sdkc:hover {
    text-decoration: underline;
  }

@media (max-width: 62.999rem) {

.article-tile_title___Sdkc {
    margin-bottom: 0.375rem;
    margin-bottom: var(--gap-tiny);
    margin-top: 0.25rem;
    min-height: unset;
}
    .article-tile_title___Sdkc h4 {
      margin-bottom: 0;
    }
  }

@media (max-width: 47.999rem) {

.article-tile_title___Sdkc {
    margin-top: 0;
}
  }

.article-tile_tags__8nYYc {
  grid-area: tags;
  list-style: none;
  margin: -0.375rem 0 0 0;
  padding: 0;
}

.article-tile_tag__dAzyg {
  display: inline-block;
  margin-right: 0.375rem;
  margin-right: var(--gap-tiny);
}

.article-tile_tag__dAzyg::after {
    content: ', ';
  }

.article-tile_tag__dAzyg:last-child:after {
    display: none;
  }

.article-tile_description__F3ZXT {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  grid-area: description;
  -webkit-line-clamp: 3;
  margin: 0.75rem 0 0 0;
  margin: var(--gap-small) 0 0 0;
  overflow: hidden;
}

@media (max-width: 47.999rem) {

.article-tile_description__F3ZXT {
    margin-top: calc(calc(1.56 * 1.5rem) / 2);
    margin-top: calc(var(--gap-large) / 2);
}
  }

.additional-page-nav_welcomeContent__ULA10 {
  font-size: rem(20px);
  font-size: var(--text-m);
}

@media (max-width: 62.999rem) {

.additional-page-nav_welcomeContent__ULA10 {
    margin-bottom: calc(1.56 * 1.5rem);
    margin-bottom: var(--gap-large);
}
  }

.additional-page-nav_list__mCHQd {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 15.3125rem;
}

@media (max-width: 62.999rem) {

.additional-page-nav_list__mCHQd {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(0.75rem * -1);
    margin-left: calc(var(--gap-small) * -1);
    width: 100%;
}
  }

@media (max-width: 47.999rem) {

.additional-page-nav_list__mCHQd {
    align-content: center;
    align-items: center;
    display: grid;
    flex-wrap: wrap;
    grid-gap: 0.75rem;
    grid-gap: var(--gap-small);
    gap: 0.75rem;
    gap: var(--gap-small);
    grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
    justify-content: space-between;
    width: auto;
}
  }

@media (min-width: 48rem) and (max-width: 62.999rem) {

.additional-page-nav_listItem__FqnzS {
    flex-grow: 1;
    margin-bottom: 0.75rem;
    margin-bottom: var(--gap-small);
    margin-left: 0.75rem;
    margin-left: var(--gap-small);
}
  }

@media (max-width: 62.999rem) {
    .additional-page-nav_listItem__FqnzS.additional-page-nav_activeItem__Dd52l {
      display: none;
    }
  }

.additional-page-nav_navTitle__BGlbZ {
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
}

@media (min-width: 63rem) {

.additional-page-nav_navTitle__BGlbZ {
    display: none;
}
  }

.additional-page-nav_link__ihNqF {
  border-bottom: 1px solid #e4e4e3;
  border-bottom: 1px solid var(--color-neutral-40);
  display: inline-block;
  font-size: rem(20px);
  font-size: var(--text-m);
  outline: none;
  padding: 0.75rem;
  padding: var(--gap-small);
  padding-left: calc(1.56 * 1.5rem);
  padding-left: var(--gap-large);
  position: relative;
  text-decoration: none;
  width: 100%;
}

.additional-page-nav_link__ihNqF::after {
    background-color: #ff3368;
    background-color: var(--color-accent-primary);
    content: '';
    height: 70%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;
    transition: var(--transition-duration) ease-in-out;
    width: 12px;
  }

.additional-page-nav_link__ihNqF:not(.additional-page-nav_activeLink__Qci86).additional-page-nav_focus-visible__7I0I4::after, .additional-page-nav_link__ihNqF:not(.additional-page-nav_activeLink__Qci86):hover::after {
        opacity: 0.5;
      }

.additional-page-nav_link__ihNqF:not(.additional-page-nav_activeLink__Qci86):focus-visible::after, .additional-page-nav_link__ihNqF:not(.additional-page-nav_activeLink__Qci86):hover::after {
        opacity: 0.5;
      }

@media (max-width: 62.999rem) {

.additional-page-nav_link__ihNqF {
    background-color: rgba(255,255,255,1);
    background-color: var(--background-color-primary);
    border: 1px solid #87b7c6;
    border: 1px solid var(--color-primary-300);
    border-radius: 99px;
    color: #5f808b;
    color: var(--color-primary-400);
    font-size: rem(14px);
    font-size: var(--text-xs);
    font-weight: 700;
    padding: 0.375rem 1.5rem;
    padding: var(--gap-tiny) var(--gap);
    padding-left: calc(1.56 * 1.5rem);
    padding-left: var(--gap-large);
    padding-right: calc(1.56 * 1.5rem);
    padding-right: var(--gap-large);
    text-align: center;
    text-transform: uppercase;
}

    .additional-page-nav_link__ihNqF.additional-page-nav_focus-visible__7I0I4,
    .additional-page-nav_link__ihNqF:hover {
      background-color: rgb(242, 242, 242);
    }

    .additional-page-nav_link__ihNqF:focus-visible,
    .additional-page-nav_link__ihNqF:hover {
      background-color: rgb(242, 242, 242);
    }

    .additional-page-nav_link__ihNqF::after {
      display: none;
    }
  }

.additional-page-nav_activeLink__Qci86 {
  color: #527079;
  color: var(--color-primary-500);
}

.additional-page-nav_activeLink__Qci86::after {
    opacity: 1;
  }

.additional-page-nav_pageName__DytFk {
  color: #908f8a;
  color: var(--color-neutral-100);
  font-family: var(--font-family);
  font-size: rem(14px);
  font-size: var(--text-xs);
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  text-transform: uppercase;
}

@media (max-width: 62.999rem) {

.additional-page-nav_pageName__DytFk {
    margin-bottom: 0.375rem;
    margin-bottom: var(--gap-tiny);
}
  }

.additional-page-nav_welcomeTitle__pEjhw {
  color: #527079;
  color: var(--color-primary-500);
}

.additional-page-nav_section__xHRQj {
  display: grid;
  grid-gap: 78px;
  grid-template-columns: auto 1fr;
}

@media (max-width: 62.999rem) {

.additional-page-nav_section__xHRQj {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;
}
  }

.articles-list_list__32T6N {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 63rem) {

.articles-list_list__32T6N {
    display: grid;
    grid-gap: calc(1.56 * 1.5rem);
    grid-gap: var(--gap-large);
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
  }

.articles-list_item__juB8q {
  margin: 0;
  padding: 0;
}

@media (max-width: 47.999rem) {
    .articles-list_item__juB8q:not(:last-child) {
      margin-bottom: calc(1.56 * calc(1.56 * 1.5rem));
      margin-bottom: var(--gap-huge);
    }
  }

.articles-section_container___0wMj {
  background-color: #f7f7f6;
  background-color: var(--color-neutral-20);
}

.articles-section_section__1P1u6 {
  display: grid;
  grid-gap: calc(1.56 * calc(1.56 * 1.5rem)) 1.5rem;
  grid-gap: var(--gap-huge) var(--gap);
  grid-template-areas: 'title link' 'articles articles';
  grid-template-columns: 1fr auto;
}

@media (max-width: 47.999rem) {

.articles-section_section__1P1u6 {
    gap: 0;
    grid-template-areas: 'title' 'articles' 'link';
    grid-template-columns: 1fr;
}
  }

.articles-section_title___kHCN {
  color: #606058;
  color: var(--color-neutral-500);
  grid-area: title;
}

@media (min-width: 48rem) {

.articles-section_title___kHCN {
    margin-bottom: 0;
}
  }

.articles-section_link__U9jbs {
  grid-area: link;
}

.articles-section_articles__vGn_a {
  grid-area: articles;
}

@media (max-width: 47.999rem) {

.articles-section_articles__vGn_a {
    margin-bottom: 1.5rem;
    margin-bottom: var(--gap);
}

    .articles-section_articles__vGn_a:last-child {
      margin-bottom: 0;
    }
  }

.welcome-article-section_container__J4AwR {
  background-color: #f9f4f1;
  background-color: var(--color-tertiary-50);
}

.welcome-article-section_section__xwiY6 {
  display: grid;
  grid-gap: calc(1.56 * calc(1.56 * 1.5rem));
  grid-gap: var(--gap-huge);
  grid-template-columns: 1fr 1fr;
  padding-bottom: calc(1.56 * 1.5rem);
  padding-bottom: var(--gap-large);
  z-index: inherit;
}

@media (max-width: 62.999rem) {

.welcome-article-section_section__xwiY6 {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;
}

    .welcome-article-section_section__xwiY6::before {
      display: none;
    }
  }

.welcome-article-section_content__4gBtp {
  position: relative;
  z-index: 1;
}

.welcome-article-section_markdown__jixUz {
  font-size: rem(20px);
  font-size: var(--text-m);
  margin-top: calc(1.56 * 1.5rem);
  margin-top: var(--gap-large);
}

.welcome-article-section_title__ABo3c {
  color: #bf916e;
  color: var(--main-article-color);
}

.welcome-article-section_tags__6WDHf {
  align-items: center;
  display: flex;
  margin-top: calc(1.56 * 1.5rem);
  margin-top: var(--gap-large);
  position: relative;
}

.welcome-article-section_tags__6WDHf::before {
    background-image: url(/_next/static/media/tag.ff64cc21.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 14px;
    margin-right: 0.75rem;
    margin-right: var(--gap-small);
    width: 16px;
  }

@media (max-width: 62.999rem) {

.welcome-article-section_tags__6WDHf {
    justify-content: center;
}
  }

.welcome-article-section_tag__WJrpj {
  font-size: rem(16px);
  font-size: var(--text-s);
}

.welcome-article-section_imagePath__S309g {
  height: 557px;
  margin-bottom: -24%;
  position: relative;
  width: 557px;
}

.welcome-article-section_imagePath__S309g::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+CiAgICA8Y2lyY2xlIGN4PSI5IiBjeT0iOSIgcj0iMiIgZmlsbD0iI0U1RDJDNCIvPgo8L3N2Zz4K);
    bottom: 0;
    content: '';
    display: block;
    height: 300px;
    left: 0;
    position: absolute;
    transform: translate(-40%, -50%);
    width: 300px;
  }

@media (max-width: 62.999rem) {

.welcome-article-section_imagePath__S309g {
    height: 60vw;
    margin: 0 auto;
    margin-bottom: calc(1.56 * calc(1.56 * 1.5rem));
    margin-bottom: var(--gap-huge);
    width: 60vw;
}
    .welcome-article-section_imagePath__S309g::after {
      height: 30vw;
      width: 30vw;
    }
  }

@media (max-width: 47.999rem) {

.welcome-article-section_imagePath__S309g {
    height: 80vw;
    width: 80vw;
}

    .welcome-article-section_imagePath__S309g::after {
      height: 90%;
      top: 0;
      transform: none;
      width: 90%;
    }
  }

.welcome-article-section_link__4sr_f {
  --color: var(--color-tertiary-300);
  margin-top: calc(1.56 * 1.5rem);
  margin-top: var(--gap-large);
}

.welcome-article-section_imageContainer__qT2Sx {
  background-color: #e5d2c4;
  background-color: var(--color-tertiary-75);
  border: 10px solid #e5d2c4;
  border: 10px solid var(--color-tertiary-75);
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.welcome-article-section_image__akQbv {
  object-fit: cover;
  object-position: center;
}

