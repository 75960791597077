.container {
  background-color: var(--color-tertiary-50);
}

.section {
  display: grid;
  grid-gap: var(--gap-huge);
  grid-template-columns: 1fr 1fr;
  padding-bottom: var(--gap-large);
  z-index: inherit;

  @media (--tablet-) {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;

    &::before {
      display: none;
    }
  }
}

.content {
  position: relative;
  z-index: 1;
}

.markdown {
  font-size: var(--text-m);
  margin-top: var(--gap-large);
}

.title {
  color: var(--main-article-color);
}

.tags {
  align-items: center;
  display: flex;
  margin-top: var(--gap-large);
  position: relative;

  &::before {
    background-image: url('./tag.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 14px;
    margin-right: var(--gap-small);
    width: 16px;
  }

  @media (--tablet-) {
    justify-content: center;
  }
}

.tag {
  font-size: var(--text-s);
}

.imagePath {
  height: 557px;
  margin-bottom: -24%;
  position: relative;
  width: 557px;

  &::after {
    background-image: url('~icons/dot-bg-tertiary-75.svg');
    bottom: 0;
    content: '';
    display: block;
    height: 300px;
    left: 0;
    position: absolute;
    transform: translate(-40%, -50%);
    width: 300px;
  }

  @media (--tablet-) {
    height: 60vw;
    margin: 0 auto;
    margin-bottom: var(--gap-huge);
    width: 60vw;
    &::after {
      height: 30vw;
      width: 30vw;
    }
  }

  @media (--phone) {
    height: 80vw;
    width: 80vw;

    &::after {
      height: 90%;
      top: 0;
      transform: none;
      width: 90%;
    }
  }
}

.link {
  --color: var(--color-tertiary-300);
  margin-top: var(--gap-large);
}

.imageContainer {
  background-color: var(--color-tertiary-75);
  border: 10px solid var(--color-tertiary-75);
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.image {
  object-fit: cover;
  object-position: center;
}
