.welcomeContent {
  font-size: var(--text-m);
  @media (--tablet-) {
    margin-bottom: var(--gap-large);
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: rem(245px);

  @media (--tablet-) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gap-small) * -1);
    width: 100%;
  }

  @media (--phone) {
    align-content: center;
    align-items: center;
    display: grid;
    flex-wrap: wrap;
    gap: var(--gap-small);
    grid-template-columns: repeat(auto-fill, minmax(rem(200px), 1fr));
    justify-content: space-between;
    width: auto;
  }
}

.listItem {
  @media (--tablet-only) {
    flex-grow: 1;
    margin-bottom: var(--gap-small);
    margin-left: var(--gap-small);
  }

  @media (--tablet-) {
    &.activeItem {
      display: none;
    }
  }
}

.navTitle {
  margin-bottom: var(--gap);
  @media (--desktop) {
    display: none;
  }
}

.link {
  border-bottom: 1px solid var(--color-neutral-40);
  display: inline-block;
  font-size: var(--text-m);
  outline: none;
  padding: var(--gap-small);
  padding-left: var(--gap-large);
  position: relative;
  text-decoration: none;
  width: 100%;

  &::after {
    background-color: var(--color-accent-primary);
    content: '';
    height: 70%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: var(--transition-duration) ease-in-out;
    width: 12px;
  }

  &:not(.activeLink) {
    &:focus-visible,
    &:hover {
      &::after {
        opacity: 0.5;
      }
    }
  }

  @media (--tablet-) {
    background-color: var(--background-color-primary);
    border: 1px solid var(--color-primary-300);
    border-radius: 99px;
    color: var(--color-primary-400);
    font-size: var(--text-xs);
    font-weight: 700;
    padding: var(--gap-tiny) var(--gap);
    padding-left: var(--gap-large);
    padding-right: var(--gap-large);
    text-align: center;
    text-transform: uppercase;

    &:focus-visible,
    &:hover {
      background-color: color-mod(var(--background-color-primary) shade(5%));
    }

    &::after {
      display: none;
    }
  }
}

.activeLink {
  color: var(--color-primary-500);

  &::after {
    opacity: 1;
  }
}

.pageName {
  color: var(--color-neutral-100);
  font-family: var(--font-family);
  font-size: var(--text-xs);
  margin-bottom: var(--gap);
  text-transform: uppercase;

  @media (--tablet-) {
    margin-bottom: var(--gap-tiny);
  }
}

.welcomeTitle {
  color: var(--color-primary-500);
}

.section {
  display: grid;
  grid-gap: 78px;
  grid-template-columns: auto 1fr;

  @media (--tablet-) {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;
  }
}
