.mainContainer {
  --bottom-decor-height: 10vw;
  background-color: var(--color-primary-50);
  overflow: hidden;
  padding-bottom: calc((var(--bottom-decor-height) / 2));
  position: relative;
  width: 100%;

  &:after {
    background-color: var(--background-color-primary);
    border-radius: 50%;
    bottom: 0;
    box-shadow: inset 0 9px 8px rgba(0, 0, 0, 0.06);
    content: '';
    height: var(--bottom-decor-height);
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);
    width: 110vw;
    z-index: 1;
  }

  @media (--desktop) {
    --bottom-decor-height: 200px;
  }
}

.section {
  --sun-color: var(--color-secondary-200);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  @media (--tablet-) {
    flex-direction: column-reverse;
    text-align: center;
  }
}

.bgSun {
  &:before {
    background-color: var(--sun-color);
    border-radius: 50%;
    bottom: 0;
    content: '';
    height: 30vw;
    max-height: 664px;
    max-width: 664px;
    opacity: 50%;
    position: absolute;
    right: var(--gap-large);
    transform: translate(15%, 45%);
    width: 30vw;
    z-index: 0;

    @media (--tablet-) {
      display: none;
    }
  }
}

.bgImage {
  object-fit: cover;
  object-position: center;
}
