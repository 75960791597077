.container {
  background-color: var(--color-neutral-20);
}

.section {
  display: grid;
  grid-gap: var(--gap-huge) var(--gap);
  grid-template-areas: 'title link' 'articles articles';
  grid-template-columns: 1fr auto;

  @media (--phone) {
    gap: 0;
    grid-template-areas: 'title' 'articles' 'link';
    grid-template-columns: 1fr;
  }
}

.title {
  color: var(--color-neutral-500);
  grid-area: title;

  @media (--tablet) {
    margin-bottom: 0;
  }
}

.link {
  grid-area: link;
}

.articles {
  grid-area: articles;

  @media (--phone) {
    margin-bottom: var(--gap);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
