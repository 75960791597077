.tag {
  color: var(--color-tertiary-400);
  display: inline-block;
  font-size: rem(12px);
  outline: none;

  &:focus-visible,
  &:hover {
    color: color-mod(var(--color-tertiary-400) shade(60%));
  }

  & > :first-letter {
    text-transform: uppercase;
  }
}
